// Logout.tsx
import React from "react";
import { useMsal } from "@azure/msal-react";
import { useFrontChatBoot } from "../modules/front-sdk/hooks/use-front-chat-boot";

const Logout: React.FC = () => {
  const { instance } = useMsal();
  const { frontChat } = useFrontChatBoot(document.body);
  const handleLogout = () => {
    if (frontChat) {
      frontChat("shutdown", { clearSession: true });
      frontChat("init", { chatId: "90daf7ac82337a6be2f5add313aa3787" });
    }
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  };

  return (
    <button
      onClick={handleLogout}
      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
    >
      Sign Out
    </button>
  );
};

export default Logout;
