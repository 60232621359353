import { SVGProps, useEffect, useState } from "react";
import { useFrontChatBoot } from "../modules/front-sdk/hooks/use-front-chat-boot";
import { useMsal } from "@azure/msal-react";

const footerNavigation = {
  solutions: [
    { name: "Hosting", href: "#" },
    { name: "Data Services", href: "#" },
    { name: "Uptime Monitoring", href: "#" },
    { name: "Enterprise Services", href: "#" },
  ],
  support: [
    { name: "Pricing", href: "#" },
    { name: "Documentation", href: "#" },
    { name: "Guides", href: "#" },
    { name: "API Reference", href: "#" },
  ],
  company: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
    { name: "Press", href: "#" },
    { name: "Partners", href: "#" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "X",
      href: "https://x.com/epicuri_ai",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  const { frontChat, isInitialized } = useFrontChatBoot(document.body);
  const { accounts } = useMsal();
  const chatId = "90daf7ac82337a6be2f5add313aa3787";
  const [userEmail, setUserEmail] = useState("");
  const [userHash, setUserHash] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (accounts.length > 0) {
        const email = accounts[0].username;
        setUserEmail(email);
        try {
          const response = await fetch(`https://api.epicuri.ai/api/getuserhash?email=${encodeURIComponent(email)}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.text();
          setUserHash(data);
        } catch (error) {
          console.error("Error fetching user hash:", error);
        }
      }
    };
    fetchUserData();
  }, [accounts]);

  useEffect(() => {
    if (!frontChat || isInitialized || !chatId) {
      return;
    }

    if (userEmail && userHash) {
      frontChat("shutdown", { clearSession: true });
      frontChat("init", {
        chatId,
        email: userEmail,
        userHash: userHash,
      });
    } else {
      frontChat("init", { chatId });
    }
  }, [isInitialized, frontChat, userEmail, userHash, chatId]);

  return (
    <footer aria-labelledby="footer-heading" className="relative">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
        <div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2 mr-8 sm:mr-12 md:mr-16 lg:mr-20">
            {footerNavigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">&copy; 2024. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
