// PrivacyPolicy.tsx
import React from "react";

const Privacy: React.FC = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Effective Date: June 6, 2024</p>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Nutrios Inc DBA Epicuri. We are committed to protecting your personal information and your right to
        privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you
        visit our website https://www.epicuri.ai. Please read this privacy policy carefully. If you do not agree with
        the terms of this privacy policy, please do not access the site.
      </p>
      <h2>2. Information We Collect</h2>
      <p>
        We may collect information about you in a variety of ways. The information we may collect on the Site includes:
      </p>
      <ul>
        <li>
          Personal Data: Personally identifiable information, such as your name, shipping address, email address, and
          telephone number, and demographic information, such as your age, gender, hometown, and interests, that you
          voluntarily give to us when you register with the Site or when you choose to participate in various activities
          related to the Site.
        </li>
        <li>
          Derivative Data: Information our servers automatically collect when you access the Site, such as your IP
          address, your browser type, your operating system, your access times, and the pages you have viewed directly
          before and after accessing the Site.
        </li>
      </ul>
      <h2>3. Use of Your Information</h2>
      <p>
        Having accurate information about you permits us to provide you with a smooth, efficient, and customized
        experience. Specifically, we may use information collected about you via the Site to:
      </p>
      <ul>
        <li>Create and manage your account.</li>
        <li>Email you regarding your account or order.</li>
        <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
      </ul>
      <h2>4. Disclosure of Your Information</h2>
      <p>
        We may share information we have collected about you in certain situations. Your information may be disclosed as
        follows:
      </p>
      <ul>
        <li>
          By Law or to Protect Rights: If we believe the release of information about you is necessary to respond to
          legal process, to investigate or remedy potential violations of our policies, or to protect the rights,
          property, and safety of others.
        </li>
      </ul>
      <h2>5. Security of Your Information</h2>
      <p>
        We use administrative, technical, and physical security measures to help protect your personal information.
        While we have taken reasonable steps to secure the personal information you provide to us, please be aware that
        despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be
        guaranteed against any interception or other type of misuse.
      </p>
      <h2>6. Policy for Children</h2>
      <p>
        We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have
        collected personal information from a child under age 13 without verification of parental consent, we will
        delete that information as quickly as possible.
      </p>
      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices
        or for other operational, legal, or regulatory reasons.
      </p>
      <h2>8. Contact Us</h2>
      <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
      <p>Nutrios Inc DBA Epicuri</p>
      <p>info@epicuri.ai</p>
      <p>66 W Flagler St, Suite 900, Miami, FL 33130</p>
    </div>
  );
};

export default Privacy;
