import React from "react";
import { useMsal } from "@azure/msal-react";
import Logout from "./Logout"; // Assume you have a Logout component similar to Login

const Header = () => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  return (
    <header className="bg-gray-800 py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <img className="h-8" src="./epicuri-word-mark.svg?color=indigo&shade=500" alt="Epicuri" />
        {isAuthenticated && (
          <div>
            <Logout />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
