import React from "react";
import Login from "./Login";
import Footer from "./Footer";
import { useIsAuthenticated } from "@azure/msal-react";
import Header from "./Header";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Dashboard = () => {
  // Example data (unchanged)
  const news = [
    {
      id: 1,
      title: "Welcome to the Beta!",
      date: "2024-07-09",
      content: `Welcome to the Epicuri beta test! We are thrilled to have you join us on this journey to revolutionize how AI interacts with the physical world.

At Epicuri, we believe that the future of AI lies in providing real-time, unbiased, and location-aware context. This beta test is an exciting preview of what AI language models can achieve when equipped with real-time context data. While our current focus is on integrating ChatGPT, our vision extends far beyond a simple GPT wrapper.

**Why Epicuri?** Epicuri aims to bring trust back to information delivery. Unlike traditional platforms that rely on ad dollars or placement fees, our decentralized blockchain data store ensures that no gatekeeper can profit from the information. This means you receive genuine, unbiased recommendations tailored to your preferences.

**What to Expect:**
* **Integration with ChatGPT:** Experience firsthand how AI can provide relevant, real-time information. Our current integration showcases how powerful and effective AI-driven interactions can be when given accurate context.
* **Transparency and Trust:** We are committed to eliminating bias. By using blockchain technology, we ensure that the data remains transparent and trustworthy.
* **Future Tools:** Stay tuned for upcoming features, including innovative integrations with Instagram and TikTok. These tools will further enhance your experience and provide even more value.
* **Incentives:** We are developing a point system to reward our early users. More details will be shared by July 31st, so keep an eye out!

**Join Us in Shaping the Future:** Your feedback is invaluable as we refine and expand our offerings. By participating in this beta test, you are not only experiencing cutting-edge technology but also contributing to a larger mission of transforming AI interactions.

Thank you for being a part of the Epicuri community. Together, we can build a future where AI serves our needs with integrity and transparency.

Warm regards,
The Epicuri Team`,
    },
  ];
  const tasks: { id: number; task: string; date: string; points: number }[] = [];
  const totalPoints = tasks.reduce((total, task) => total + task.points, 0);

  return (
    <div className="bg-gray-900 min-h-screen">
      <main>
        <div className="relative isolate overflow-hidden">
          {/* SVG background code remains unchanged */}
          <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8 lg:py-20">
            <h1 className="text-4xl font-bold text-white mb-10">Beta Dashboard</h1>
            {/* New Epicuri GPT Link Section */}
            {/* New Epicuri GPT Link Section */}
            <section className="mb-12">
              <a
                href="https://chatgpt.com/g/g-jMAVLbjnP-epicuri"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center p-4 bg-blue-600 hover:bg-blue-700 transition-colors duration-300 rounded-lg shadow-lg"
              >
                <img src="/concierge.png" alt="Epicuri GPT Icon" className="w-8 h-8 mr-3" />
                <span className="text-xl font-semibold text-white">Launch Epicuri GPT Beta</span>
              </a>
            </section>
            <section className="mb-12">
              <h2 className="text-2xl font-bold text-gray-200 mb-4">News</h2>
              <ul>
                {news.map((item) => (
                  <li key={item.id} className="mb-4 p-4 bg-gray-800 rounded-md">
                    <h3 className="text-xl font-semibold text-white">{item.title}</h3>
                    <p className="text-gray-400">{item.date}</p>
                    <div className="text-gray-300  max-w-none">
                      <Markdown className="markdown" remarkPlugins={[remarkGfm]}>
                        {item.content}
                      </Markdown>
                    </div>
                  </li>
                ))}
              </ul>
            </section>
            <section>
              <h2 className="text-2xl font-bold text-gray-200 mb-4">Your Points</h2>
              <p className="text-gray-300 mb-4">Total Points: {totalPoints}</p>
              <ul>
                {tasks.map((task) => (
                  <li key={task.id} className="mb-4 p-4 bg-gray-800 rounded-md">
                    <p className="text-gray-300">{task.task}</p>
                    <p className="text-gray-400">{task.date}</p>
                    <p className="text-gray-300">Points: {task.points}</p>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default function BetaLanding() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        {!isAuthenticated ? (
          <div className="relative isolate overflow-hidden">
            {/* SVG background code remains unchanged */}
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto lg:max-w-xl">
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Join Epicuri Beta: Decentralized Food Discovery
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  We're testing an innovative concept: using blockchain technology to provide real-time, location-aware
                  context for AI-powered food recommendations and ordering.
                </p>
                <ul className="mt-6 text-lg leading-8 text-gray-300 list-disc list-inside">
                  <li>AI-powered recommendations from decentralized restaurant data</li>
                  <li>No gatekeepers or middlemen influencing choices or adding fees</li>
                  <li>Direct ordering links with restaurants</li>
                </ul>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  As an early tester, you'll use ChatGPT as a technology preview for personalized food discovery (soon
                  on Instagram, TikTok, others in the coming weeks). Help shape decentralized food tech, and earn
                  rewards in our upcoming point system (details by July 31).
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Currently focused on Miami and New York City, expanding steadily.
                </p>
                <div className="mt-10">
                  <Login />
                </div>
              </div>
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <img
                  src="/stylized_map.png"
                  alt="AI-powered food discovery"
                  className="mx-auto w-full max-w-[38rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                />
              </div>
            </div>
          </div>
        ) : (
          <Dashboard />
        )}
      </main>
      <Footer />
    </div>
  );
}
