import { Configuration, PopupRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "790e685b-64c6-494b-ab08-49bca389d3ac",
    authority: "https://auth.epicuri.ai/epicuri.onmicrosoft.com/B2C_1_epicuri",
    knownAuthorities: ["auth.epicuri.ai"],
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: PopupRequest = {
  scopes: ["openid", "profile", "email"],
};
