import React, { useCallback, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import BetaLanding from "./components/BetaLanding";
import Privacy from "./components/Privacy";
import TOS from "./components/TOS";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useFrontChatBoot } from "./modules/front-sdk/hooks/use-front-chat-boot";

function App() {
  const { instance, accounts } = useMsal();
  const { frontChat } = useFrontChatBoot(document.body);
  const frontChatRef = useRef(frontChat);
  const chatId = "90daf7ac82337a6be2f5add313aa3787";

  const acquireTokenSilently = useCallback(async () => {
    const account = instance.getAllAccounts()[0];
    if (account && account.username) {
      try {
        const silentRequest = {
          scopes: ["openid", "profile", "email"],
          account: account,
        };
        await instance.acquireTokenSilent(silentRequest);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.loginRedirect();
        }
      }
    }
  }, [instance]);

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      acquireTokenSilently();
    }
  }, [acquireTokenSilently, instance]);

  useEffect(() => {
    // Update the ref whenever frontChat changes
    frontChatRef.current = frontChat;
  }, [frontChat]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (accounts.length > 0) {
        const email = accounts[0].username;
        try {
          const response = await fetch(`https://api.epicuri.ai/api/getuserhash?email=${encodeURIComponent(email)}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const userHash = await response.text();
          if (frontChatRef.current) {
            frontChatRef.current("shutdown", { clearSession: true });
            frontChatRef.current("init", {
              chatId,
              email: email,
              userHash: userHash,
            });
          }
        } catch (error) {
          console.error("Error fetching user hash:", error);
        }
      }
    };
    fetchUserData();
  }, [accounts, chatId]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/beta" element={<BetaLanding />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tos" element={<TOS />} />
      </Routes>
    </Router>
  );
}

export default App;
