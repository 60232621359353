// Login.tsx
import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const Login: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    const nonce = "1234"; // TODO: replace this with ephemeral keypair nonce
    // Extend the loginRequest with extraQueryParameters
    const extendedLoginRequest = {
      ...loginRequest,
      extraQueryParameters: {
        ...loginRequest.extraQueryParameters, // Keep existing extraQueryParameters if any
        nonce: nonce,
      },
    };

    instance.loginPopup(extendedLoginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <button
      onClick={handleLogin}
      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
    >
      Sign In/Sign Up
    </button>
  );
};

export default Login;
