// TermsOfService.tsx
import React from "react";

const TOS: React.FC = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>Effective Date: June 6, 2024</p>
      <h2>1. Agreement to Terms</h2>
      <p>
        These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf
        of an entity (“you”) and [Your Company Name] (“we,” “us” or “our”), concerning your access to and use of the
        https://www.epicuri.ai website as well as any other media form, media channel, mobile website or mobile
        application related, linked, or otherwise connected thereto (collectively, the “Site”).
      </p>
      <p>
        You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of
        Service. If you do not agree with all of these Terms of Service, then you are expressly prohibited from using
        the Site and you must discontinue use immediately.
      </p>
      <h2>2. Intellectual Property Rights</h2>
      <p>
        Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled
        by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual
        property rights and unfair competition laws of the United States, foreign jurisdictions, and international
        conventions.
      </p>
      <h2>3. User Representations</h2>
      <p>By using the Site, you represent and warrant that:</p>
      <ul>
        <li>All registration information you submit will be true, accurate, current, and complete.</li>
        <li>
          You will maintain the accuracy of such information and promptly update such registration information as
          necessary.
        </li>
      </ul>
      <h2>4. Prohibited Activities</h2>
      <p>
        You may not access or use the Site for any purpose other than that for which we make the Site available. The
        Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or
        approved by us.
      </p>
      <h2>5. Modifications and Interruptions</h2>
      <p>
        We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our
        sole discretion without notice. However, we have no obligation to update any information on our Site. We also
        reserve the right to modify or discontinue all or part of the Site without notice at any time.
      </p>
      <h2>6. Governing Law</h2>
      <p>
        These Terms of Service and your use of the Site are governed by and construed in accordance with the laws of the
        State of [Your State] applicable to agreements made and to be entirely performed within the State/Commonwealth
        of [Your State], without regard to its conflict of law principles.
      </p>
      <h2>7. Contact Us</h2>
      <p>
        In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site,
        please contact us at:
      </p>
      <p>Nutrios Inc DBA Epicuri</p>
      <p>info@epicuri.ai</p>
      <p>66 W Flagler St, Suite 900, Miami, FL 33130</p>
    </div>
  );
};

export default TOS;
